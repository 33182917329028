export enum ScreenType {
  NeverLoggedIn = -2,
  LoggedOut = -1,
  Unknown,
  StudentDashboard,
  StudentLibrary,
  StudentPortfolio,
  StudentProgress,
  StudentBadgeLibrary,
  StudentGlobalSearch,
  StudenteReader,
  PowerChallenge,
  Newsstand,
  WordWork = 10,
  AuthorBooks,
  TeacherDashboardStudentActivity,
  TeacherDashboardFullScreen,
  FluencyBuilder,
  EnhancedReader = 15, // R15 on API
  FollowStudent = 16,
  Homework = 17,
  ContentBuilder = 18,
  LightSailSharedLibrary = 19,
  DistrictSharedLibrary = 20,
  PartnerSharedLibrary = 21,
  PrivateLibrary = 22,
  TeacherClassProgress = 23,
  TeacherClassSettings = 24,
  RubricBuilder = 25,
  BuyBooks = 26,
  ReportClassStats = 27,
  ReportStudentClozes = 28,
  ReportStudentAssessments = 29,
  ReportStudentLexile = 30,
  ReportStudentReadingActivity = 31,
  ReportDistrictStatsReport = 32,
  ReportSchoolStatsReport = 33,
  StudentRoster = 34,
  TeacherRoster = 35,
  AdminRoster = 36,
  TrendsMissedClozes = 37,
  TrendsMinutesRead = 38,
  TrendsReadingStandard = 39,
  TrendsClozeRetakes = 40,
  TrendsTexts = 41,
  ConsumerHomepage = 42,
  ConsumerBooks = 43,
  ConsumerVideos = 44,
  ConsumerActionClips = 45,
  ConsumerMyJournal = 46,
  ConsumerReader = 47,
  ConsumerWordWork = 48,
  ConsumerChat = 49,
  ConsumerAssignments = 50,
  ConsumerSpellingBee = 51,
  ConsumerAuthorABook = 52,
  ConsumerImages,
  ConsumerAudioBooks,
  WorldBookMapsAndAtlas,
  WorldBookEncyclopedia,
  WorldBookTimelines,
  WorldBookEarlyLearning,
  WorldBookKids,
  WorldBookBehindTheHeadlines,
  WorldBookBrowseContent,
  WorldBookWizard,
  LernerSports,
  BiographyCenter,
  BiographyCenterBiographyCollections,
  LernerInteractiveBooks,
  TEDEdLibrary,
  LibraryLiveFeed,
  LibraryGoogleStreetView,
  UnitStudies,
  LernerAugmentedReality,
  WorldBook,
  BooksMostPopular,
  BooksClassics,
  BooksFaithBased,
  BooksPowerTexts,
  PremiumBooks,
  LibraryShelves,
  LibraryBookmarks,
  LibraryQuotations,
  BrowseBySubject,
  ClassicBooksLibrary,
  ClassicAudioBooksLibrary,
  FollowListenPerWord,
  FLPWLibrarySearch,
  FLPWBrowseBySubject,
  FollowListenPerSentence,
  FLPSLibrarySearch,
  FLPSBrowseBySubject,
  LernerAudioOnly,
  LernerAudioLibrarySearch,
  LernerAudioBrowseBySubject,
  LernerInteractiveLibrarySearch,
  LernerInteractiveBrowseBySubject,
  WorldBookEncyclopediaWhatsPopular,
  WorldBookEncyclopediaArticles,
  WorldBookEncyclopediaBackInTime,
  WorldBookEncyclopedia360Images,
  WorldBookEncyclopediaArticlesLibrary,
  WorldBookEncyclopediaArticlesBrowseBySubject,
  VideoLibrary,
  ActionClipLibrary,
  SearchVideos,
  VideoBrowseBySubject,
  SearchTedEd,
  TedEdBrowseBySubject,
  SearchActionClips,
  ActionClipBrowseBySubject,
  CreateUnitStudy,
  LightSailUnitStudies,
  MyActiveUnitStudies,
  MyDraftUnitStudies,
  ViewUnitStudyChildResults,
  MyUnitStudies,
  MySharedUnitStudies,
  ViewUnitStudyResults,
  SearchUnitStudy,
  SearchBooksLibrary,
  BrowseByAuthor,
  ReadingDashboard,
  WritingDashboard,
  VocabularyDashboard,
  FluencyDashboard,
  VocabularyRubricBuilder,
  BookLists,
  MyLists,
  LightSailLists,
  AssignedRecommendedContent,
  MyChildsAssignedRecommendedContent,
  MyBookClubs,
  MyAssignments,
  MyData,
  BookClubs,
  GradeBook,
  MyChildrenData,
  AssignmentBuilder,
  MyChildJournal,
  MyJournal,
  AuthenticWriting,
  WritingFluency,
  BookReport,
  EssayWriting,
  EssayAndInquiryWriting,
  InquiryWriting,
  WritingSkills,
  SpellingBeeInBook,
  ReadAloudRetelling,
  FluencyResults,
  ActiveAssignments,
  AssignmentsToReview,
  BrowseBooks,
  LibraryBookmarksQuotations,
  CreateNewBookClub,
  SearchBookClubs,
  CreatedBookClubs,
  JoinedBookClubs,
  SearchAudioBooks,
  SearchVideoLibrary,
  ReadingGoals,
  ReadingMilestones,
  OpenContent,
  ReadingHistory,
  ReadingBadges,
  ReadingGradeBook,
  BooksHistory,
  AudioBooksHistory,
  EncyclopediaArticlesHistory,
  VideosHistory,
  InteractiveBooksHistory,
  AugmentedRealityHistory,
  AllBadges,
  GenreBadges,
  AchievementBadges,
  TournamentBadges,
  TeamReadingBadges,
  GrammarBadges,
  LexileGrowth,
  Assessments,
  Totals,
  Calendar,
  WritingBadges,
  WritingGradeBook,
  AWInProgress,
  AWReview,
  AWResults,
  Vocabulary,
  Clozes,
  DefinedWords,
  Spelling,
  WordWorkClozes,
  WordWorkSpelling,
  VocabularyBadges,
  VocabularyGradeBook,
  MyChildReadAlouds,
  FluencyBadges,
  FluencyGradeBook,
  MyChildVocabularyData,
  MyChildFluencyData,
  MemoryModule,
  MyReadingAssignments,
  MyWritingAssignments,
  MyVocabularyAssignments,
  MyFluencyAssignments,
  MyReadAlouds,
  Assign,
  InformalWriting,
  WriteNewBook,
  AuthorBookDrafts,
  AuthorBookAboutMe,
  AuthorBookInReview,
  AuthorBookReviewMyChildrenBooks,
  AuthorBookPublished,
  AuthorBookFamilyPublished,
  AuthorBookChildFamilyPublished,
  AuthorBookPeerReview,
  AuthorBookChildMyPublished,
  StudentAuthoredBooks,
  TeacherAuthoredBooks,
  Progress,
  GoalsAndAchievements,
  MeasureTrends,
  ProgressCalendars,
  Library,
  LibraryPublishedBooks,
  Assignments,
  GrammarRemediation,
  ReadingAssignments,
  FluencyAssignments,
  SpellingBee,
  Portfolio,
  PortfolioReadingHistory,
  PortfolioVideoHistory,
  PortfolioReadingAssignments,
  PortfolioAssessments,
  PortfolioDecodableAssessments,
  PortfolioFluencyResults,
  PortfolioVocabulary,
  PortfolioAuthenticWriting,
  PortfolioTournamentHistory,
  PortfolioAwardedBadges,
  SchoolVideoLibrary,
  Reading,
  ReadingContentBuilder,
  ReadingHomeworkPlans,
  ReadingTournaments,
  ReadingTeamReading,
  ReadingPersonalizedReader,
  ReadingGradebook,
  CreateHomeworkPlans,
  ViewMissingHomework,
  ViewHomeworkPlans,
  CreateReadingTeam,
  TeacherLedTeams,
  LiteracyCircles,
  StudentResults,
  ContentBuilderAddContent,
  ContentBuilderDrafts,
  ContentBuilderAssigned,
  AssignmentScheduler,
  ContentBuilderShared,
  ResourceUnit,
  ContentBuilderGroups,
  ContentBuilderGradebook,
  ContentBuilderResults,
  ReadingRubricBuilder,
  Writing,
  WritingAuthenticWriting,
  WritingAuthorABook,
  WritingSpeakListenWrite,
  WritingRubricBuilder,
  WritingEssayWriting,
  RostersSettings,
  ClassSettings,
  ClassRoster,
  ReportsData,
  Reports,
  Trends,
  ClassProgress,
  CurrentStatusReport,
  ReportTutorial,
  Fluency,
  RunningRecord,
  FluencyRubricBuilder,
  TeamReading,
  Chat,
  Home,
  ReportStudentReadingSkills,
  Feed,
  WordJourney,
  AddNewWordRule,
  AddNewWord,
  ImportNewWords,
  ViewStudentWordRules,
  WordJourneyViewStudentGrammarRules,
  ViewStudentVocabularyWords,
  GrammarRemediationResults,
  Tournaments,
  RetakeClozes,
  Notifications,
  TournamentScoreboard,
  TournamentLeaderboard,
  ReportReadingProgressReport,
  ReportSchoolSummaryReport,
  ReportDistrictSummaryReport,
  ManageMySchool,
  ManageSchools,
  Shop,
  BookFairs,
  BookRequests,
  Orders,
  MissedClozes,
  MinutesRead,
  ReadingStandard,
  ClozeRetakes,
  BookCheckouts,
  TeacherWriteNewBook,
  TeacherAuthorBookDrafts,
  TeacherAuthorBookPublished,
  TeacherAuthorBookReviewStudent,
  StudentAuthorBookPeerReview,
  BrowseByGenre,
  BrowseBySeries,
  AddNewRubric,
  ActiveRubrics,
  ViewArchivedRubrics,
  CreateTournament,
  ActiveTournaments,
  UpcomingTournaments,
  CompletedTournaments,
  CreateFluencyAssignment,
  DraftFluencyAssignments,
  ActiveFluencyAssignments,
  PastFluencyAssignments,
  ReportingCapabilitiesForAdministrators,
  ReportingCapabilitiesForTeacher,
  ReportingCapabilitiesForStudentsAndParents,
  AssignOneTimeSpellingBee,
  AssignRepetitiveSpellingBee,
  SpellingBeeResults,
  AddNewGrammarRule,
  AssignOneTimeRemediationAssignment,
  AssignOngoingRemediationAssignment,
  AssignAutomatedRemediationAssignment,
  ViewStudentGrammarRules,
  VocabularyStudentResults,
  WordWorkResults,
  GrammarTournaments,
  ClozeResults,
  StudentResultsSpellingBeeResults,
  WordWorkClozeResults,
  WordWorkSpellingBeeResults,
  WordWorkGrammarRemediationResults,
  WritingAssignments,
  VocabularyAssignments,
  GrammarRemediationAssignments,
  ClassGoalsAndAchievements,
  ClassMeasureTrends,
  ClassProgressCalendars,
  CombinedClassProgress,
  CombinedStudentProgress,
  StudentCombinedStudentProgress,
  StudentGoalsAndAchievements,
  StudentMeasureTrends,
  StudentProgressCalendars,
  PortfolioClozeAssessments,
  PortfolioDefinedWords,
  PortfolioSpellingBeeWords,
  PortfolioWordJourneys,
  PortfolioGrammarRemediation,
  PortfolioWriting,
  PortfolioEssayWriting,
  PortfolioAuthorABook,
  StudentTeacherLedTeams,
  StudentLiteracyCircles,
  CreateWritingAssignment,
  DraftWritingAssignments,
  ActiveWritingAssignments,
  PastWritingAssignments,
  WritingGradeBookAuthenticWriting,
  WritingGradeBookPublishedBooks,
  WritingGradeBookSpeakListenWrite,
  WritingGradeBookShortResponse,
  GrammarAssignments,
  Grammar,
  Grammar_PartsOfSpeech,
  Grammar_Punctuation,
  Grammar_SentenceStructure,
  Grammar_CommonMistakes,
  Grammar_GrammarTournaments,
  Grammar_GrammarResults,
  Grammar_GrammarWorks,
  Grammar_GrammarRemediation,
  Grammar_PartsOfSpeech_View,
  Grammar_PartsOfSpeech_Add,
  Grammar_PartsOfSpeech_Results,
  Grammar_PartsOfSpeech_WorkResults,
  Grammar_PartsOfSpeech_Remediation,
  Grammar_PartsOfSpeech_Remediation_View,
  Grammar_PartsOfSpeech_Remediation_OneTime,
  Grammar_PartsOfSpeech_Remediation_Ongoing,
  Grammar_PartsOfSpeech_Remediation_Automated,
  Grammar_PartsOfSpeech_Remediation_Results,
  Grammar_Punctuation_View,
  Grammar_Punctuation_Add,
  Grammar_Punctuation_Results,
  Grammar_Punctuation_WorkResults,
  Grammar_Punctuation_Remediation,
  Grammar_Punctuation_Remediation_View,
  Grammar_Punctuation_Remediation_OneTime,
  Grammar_Punctuation_Remediation_Ongoing,
  Grammar_Punctuation_Remediation_Automated,
  Grammar_Punctuation_Remediation_Results,
  Grammar_CommonMistakes_View,
  Grammar_CommonMistakes_Add,
  Grammar_CommonMistakes_Results,
  Grammar_CommonMistakes_WorkResults,
  Grammar_CommonMistakes_Remediation,
  Grammar_CommonMistakes_Remediation_View,
  Grammar_CommonMistakes_Remediation_OneTime,
  Grammar_CommonMistakes_Remediation_Ongoing,
  Grammar_CommonMistakes_Remediation_Automated,
  Grammar_CommonMistakes_Remediation_Results,
  GrammarAssignments_PartsOfSpeech,
  GrammarAssignments_Punctuation,
  GrammarAssignments_SentenceStructure,
  GrammarAssignments_CommonMistakes,
  GrammarAssignments_AdditionalPractice,
  LogStudentPaperReadData,
  ClassChat,
  StudentGroupsChat,
  CommunityGroupsChat,
  ChatWithTeacher,
  Decodables,
  Decodables_Reports,
  Decodables_ConceptCollections,
  Decodables_BrowseArticles,
  Decodables_BrowseArticlesByGrade,
  Decodables_AssignPacingGuides,
  Decodables_PacingGuides,
  Decodables_ScopeAndSequence,
  Decodables_PacingGuides_Kindergarten,
  Decodables_PacingGuides_Grade_1,
  Decodables_PacingGuides_Grade_2,
  Decodables_PacingGuides_Grade_3,
  Decodables_PacingGuides_Kindergarten_MS,
  Decodables_PacingGuides_Grade_1_MS,
  Decodables_PacingGuides_Grade_2_HS,
  Decodables_PacingGuides_Grade_3_HS,
  Decodables_ViewStudentAssignedPacingGuideStudentSettings,
  Decodables_StudentPathways,
  Decodables_StudentGradeLevelJourney,
  Decodables_StudentDetailedJourney,
  Decodables_ClassGradePathway,
  Decodables_ClassDetailedPathway,
  Decodables_SearchForConcepts,
  Decodables_Assignments,
  Decodables_MyBadges,
  Decodables_MyPathway,
  Decodables_MyJourney,
  Decodables_MyResults,
  Library_DecodableArticles,
  Decodables_ConceptCollections_Library,
  Decodables_ConceptCollections_FocusWords,
  Decodables_ConceptCollections_SightWords,
  Decodables_ConceptCollections_AdvSightWords,
  Decodables_ConceptCollections_SuperStarWords,
  Decodables_ConceptCollections_ComprehensionQuestions,
  Decodables_SmallGroups,
  Decodables_Assessment_Gradebook,
  Decodables_Library,
  Decodables_Library_ByGrade,
  Decodables_Library_ConceptCollections,
  Decodables_Library_Audiobooks,
  Decodables_Library_Antares,
  Decodables_Library_CreateJourney,
  Decodables_Library_ViewJourneys,
  Decodables_Library_MyClassLibrary,
  Decodables_Library_MyClassLibrary_StudentAuthored,
  Decodables_Library_MyClassLibrary_TeacherAuthored,
  Decodables_Library_MyClassLibrary_LightSailUniverse,
  Decodables_Library_MyClassLibrary_ContentBuilder,
  Decodables_Library_MyClassLibrary_DistrictShared,
  Decodables_Library_MainLibrary,
  ConceptProficiencyReport,
  ReportWordGrammarJourneyProgress,
  Decodables_Library_ScopeAndSequence,
  Decodables_Library_PacingGuides,
  Decodables_Library_PacingGuides_Kindergarten,
  Decodables_Library_PacingGuides_Grade_1,
  Decodables_Library_PacingGuides_Grade_2,
  Decodables_Library_PacingGuides_Grade_3,
  Decodables_Library_PacingGuides_Kindergarten_MS,
  Decodables_Library_PacingGuides_Grade_1_MS,
  Decodables_Library_PacingGuides_Grade_2_HS,
  Decodables_Library_PacingGuides_Grade_3_HS,
  StudentCoach,
  StudentAudioBookPlayer,
  ReportBookUsage,
  StudentMaps
}
